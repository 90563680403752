<template>
  <Suspense>
    <router-view />
  </Suspense>
  <OverlayLogin />
  <OverlayRegister />
  <OverlayFoundingTraderApplication />
  <OverlayWaitlist />
  <OverlayCardDetails />
  <Alerts />
</template>

<script lang="ts">
  import * as Vue from "vue";
  import OverlayLogin from "@contrarian/ui-shared/components/OverlayLogin.vue";
  import OverlayRegister from "@contrarian/ui-shared/components/OverlayRegister.vue";
  import OverlayWaitlist from "./components/OverlayWaitlist.vue";
  import OverlayCardDetails from "./components/OverlayCardDetails.vue";
  import OverlayFoundingTraderApplication from "./components/OverlayFoundingTraderApplication.vue";
  import Alerts from "@/components/Alerts.vue";

  export default Vue.defineComponent({
    components: { 
      Alerts, 
      OverlayLogin, 
      OverlayRegister, 
      OverlayWaitlist,
      OverlayCardDetails,
      OverlayFoundingTraderApplication,
    },
  });
</script>
