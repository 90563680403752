<template>
<div class="NAV-FOR-PUBLIC-COMPONENT">
  <div v-if="invite" class="FREE-BOX-OF-AMMO pt-4 pb-3" @click="openCardDetails">
    <div class="flex mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex-shrink-0">
        <GiftIcon class="h-6 w-6" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <p v-if="invite.isValid" class="text-xs md:text-sm font-medium text-gray-800">
          <span v-if="invite.firstName">{{invite.firstName}}, your </span>
          <span v-else>Your </span>
          <span class="hidden md:inline">founding trader </span>invite expires in
          <span class="hidden md:inline relative countdown font-ammocorps text-lg" style="top: 1px;">{{inviteCountdown?.hours}} hours, {{inviteCountdown?.minutes}} minutes, {{inviteCountdown?.seconds}} seconds</span>
          <span class="block md:hidden countdown font-ammocorps text-sm">{{inviteCountdown?.hours}} hrs, {{inviteCountdown?.minutes}} mins, {{inviteCountdown?.seconds}} secs</span>
        </p>
        <p v-else-if="invite.hasBeenRedeemed">
          Your Founding Trader link has already been used.
        </p>
        <p v-else>
          Your Founding Trader link has expired.
        </p>
      </div>
      <div v-if="invite.isValid" class="ml-auto pl-3">
        <div class="-my-1">
          <button @click="openRegister" type="button" :class="[showFoundingTraderButton ? 'inline-flex' : ' hidden']" class="free-ammo-button relative mt-1 px-3 py-1 text-sm font-light rounded focus:outline-none" style="text-shadow: 1px 1px 0 rgba(255,255,255,0.6); ">
            <span class="hidden md:block">Become a Founding Trader</span>
            <span class="block md:hidden">Register</span>
          </button>
        </div>
      </div>
      <div v-else class="ml-auto">
        <div class="-my-1">
          <button @click="clearInvite" type="button" class="inline-flex rounded-md p-1 px-3 focus:outline-none">
            X
          </button>
        </div>
      </div>
    </div>
  </div>

  <nav :class="[isDarkTheme ? 'border-black' : '']" class="border-b relative z-[1]" :style="[isDarkTheme ? 'box-shadow: 0 1px 0 rgba(255,255,255,0.2)' : '']">
    <Disclosure as="header" :class="[isDarkTheme ? 'bg-[#1f2b3a]' : 'bg-white']" class="shadow">
      <div class="mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between relative h-16">
          <div class="">
            <router-link 
              to="/" 
              class="absolute block h-auto w-28 md:w-40 top-0 left-0 px-[8px] md:px-[12px] pt-[2px] md:pt-[8px] pb-[10px] md:pb-12px text-center border border-black border-t-0 rounded-sm rounded-t-none shadow"
              style="background-color: #141618"
            >
              <InlineSvg
                class="LOGO relative inline-block opacity-80 w-full"
                :src="require(`@/assets/${appId}/logo-full.svg`)"
                alt="Ammo Exchange"
              />
            </router-link>
          </div>
          <div class="flex flex-row items-center justify-end">
            <div class="ml-4 flex items-center md:ml-6">
              <div class=" md:block">
                <div class="ml-10">
                  <div v-if="memberId" class="pl-5 flex space-x-3">
                    <a :href="`//${platformDomain}/`" :class="[isDarkTheme ? 'text-gray-300' : 'text-gray-600']" class="px-3 py-2 text-sm font-light cursor-pointer border border-slate-600 rounded">Open Your Trading Dashboard <ChevronDoubleRightIcon class="relative -top-[1px] h-4 inline"/></a>
                  </div>
                  <div v-else class="sm:pl-5 pl-14 flex space-x-2">
                    <router-link to="/why-ammo-now" :class="[isDarkTheme ? 'text-gray-300' : 'text-gray-600']" class="sm:px-3 sm:py-2 sm:text-sm text-[14px] font-light cursor-pointer whitespace-nowrap py-[3px] px-[2px]">Why Ammo?</router-link>
                    
                    <a @click="openLogin" :class="[isDarkTheme ? 'text-gray-300' : 'text-gray-600']" class="sm:px-3 sm:py-2 sm:text-sm text-[14px] font-light cursor-pointer whitespace-nowrap py-[3px] px-[2px]">Login</a>
                    
                    <a @click="openRegister" :class="[isDarkTheme ? 'text-gray-900' : 'text-gray-600']" class="sm:px-3 sm:py-2 sm:text-sm text-[14px] font-light cursor-pointer border border-black bg-blue-100 rounded whitespace-nowrap py-[3px] px-[6px]" style="text-shadow: 1px 1px 0 rgba(255,255,255,0.5)">
                      <span class="">Create Account</span>
                    </a>
                    <!-- <router-link to="/faqs" :class="[isDarkTheme ? 'text-gray-300' : 'text-gray-600']" class="px-3 py-2 text-sm font-light cursor-pointer">FAQs</router-link> -->
                    <!-- <a @click="openRegister" :class="[isDarkTheme ? 'text-gray-900' : 'text-gray-600']" class="px-3 py-2 text-sm font-light cursor-pointer border border-black bg-blue-100 rounded" style="text-shadow: 1px 1px 0 rgba(255,255,255,0.5)">
                      <span class="hidden md:block">Become a Founding Trader</span>
                      <span class="block md:hidden">Become a Trader</span>
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  </nav>
  </div>
</template>

<script lang="ts">
  import * as Vue from 'vue';
  import {useStore} from "vuex";
  import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
  import inviteModule from "@contrarian/ui-shared/modules/inviteModule";
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
  import { GiftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/20/solid'
  import { XMarkIcon } from '@heroicons/vue/24/outline'

  export default Vue.defineComponent({
    components: {
      XMarkIcon,
      GiftIcon,
      ChevronDoubleRightIcon,
      Disclosure, 
      DisclosureButton, 
      DisclosurePanel,
    },
    props: {
      isDarkTheme: Boolean,
    },
    setup() {
      const emitter = useEmitter();
      const store = useStore();

      const platformDomain = process.env.VUE_APP_PLATFORM_DOMAIN || '';
      const { memberId } = store.state.session;
      const { invite, inviteCountdown } = inviteModule(store);
      
      if (invite) {
        invite.isValid = inviteCountdown && inviteCountdown.value.totalMillis > 0 && !invite.hasBeenRedeemed;
      }

      if (invite?.hasBeenRedeemed || inviteCountdown && inviteCountdown.value.totalMillis <= 0) {
        store.commit('session/clearInvite');
      }

      return {
        emitter,
        store,
        appId: process.env.VUE_APP_ID,
        invite,
        inviteCountdown,
        platformDomain,
        memberId,
        showFoundingTraderButton: Vue.ref(false),
      }
    },
    mounted() {
      window.addEventListener('scroll', this.tryShowFreeAmmoButton.bind(this)); 
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.tryShowFreeAmmoButton.bind(this)); 
    },
    methods: {
      tryShowFreeAmmoButton(event: any) {
        this.showFoundingTraderButton = (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50);
      },
      openLogin() {
        this.emitter.emit('openLogin');
      },
      openRegister(event: MouseEvent) {
        event.stopImmediatePropagation();
        this.emitter.emit('openRegister');
      },
      openCardDetails() {
        this.emitter.emit('openCardDetails');
      },
      openWaitlist() {
        this.emitter.emit('openWaitlist');
      },
      clearInvite() {
        this.invite = undefined;
        this.store.commit('session/clearInvite');
      }
    }
  });
</script>

<style lang="scss">
.NAV-FOR-PUBLIC-COMPONENT {
  .FREE-BOX-OF-AMMO {
    @apply bg-[#FFFAE1] cursor-pointer hover:bg-[#F7F1D4];
    border-bottom: 1px solid rgba(87, 79, 39, 0.3);
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    z-index: 9;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 61px;
    button {
      background: #F7EBAC;
      border: 1px solid #c8bf90;
      &:hover {
        box-shadow: inset 1px 1px 0 white;
      }
    }
    .countdown {
      color: #5D5842;
    }
    & + nav {
      margin-top: 61px;
    }
  }

  svg.LOGO {
    path {
      fill: white;
    }
  }
}
</style>
