<template>
  <div class="footer">
    <!-- <div class="footer-top">
      <div class="footer-top-left">
        <ul>
          <li>Home</li>
          <li>About</li>
          <li>Newsroom</li>
          <li>Pricing</li>
          <li>FAQs</li>
          <li>Contact</li>
        </ul>
      </div>
      <div class="footer-top-right">
        <h1>Subscribe to Market Updates</h1>
        <h2 class="mt-2">Discover new product features and get industry news and insights.</h2>
        <button
          class="bg-gray-400 hover:bg-gray-500 text-white font-bold py-2 px-4 rounded mt-4"
        >Subscribe</button>
      </div>
    </div> -->
    <div class="footer-bottom">
      <div class="footer-bottom-left">
        <h1>© Copyright 2021, USAE LLC. All rights reserved.</h1>
      </div>
      <div class="footer-bottom-right">
        <router-link to="/">404-860-1284</router-link>
        <router-link to="/">support@ammoexchange.com</router-link>
        <router-link to="/legal">User Agreement</router-link>
        <!-- <img src="@/assets/images/insta.svg" />
        <img src="@/assets/images/facebook.svg" />
        <img src="@/assets/images/twitter.svg" /> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import ContentWrapper from '@/layouts/ContentWrapper.vue';
import {
  config
} from "@/main";

export default Vue.defineComponent({
  components: {
    ContentWrapper
  },
  setup() {

    return {
      legalOwner: config.legalOwner,
    }
  }
});
</script>

<style lang="scss" scoped>
.footer {
  background-color: #3d4957;
  padding: 25px 50px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-top {
    display: flex;
    padding-top: 25px;
    padding-bottom: 30px;
    width: 100%;

    .footer-top-left {
      background-color: #2f3945;
      width: 50%;
      border-radius: 5px;

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 15px;

        li {
          padding: 15px;
          width: 50%;
          color: white;
          opacity: 0.7;
        }

        li:hover {
          opacity: 1;
          cursor: pointer;
        }
      }
    }

    .footer-top-right {
      width: 50%;
      height: 100%;
      display: flex;
      text-align: right;
      align-items: flex-end;
      justify-content: center;
      flex-direction: column;
      color: white;
      padding-left: 15px;
      margin-top: 20px;

      h1 {
        font-size: 1.4rem;
        font-weight: 700;
        max-width: 400px;
      }

      h2 {
        font-weight: 300;
        max-width: 300px;
      }

      button {
        background-color: #697891;
        opacity: 0.7;
      }

      button:hover {
        opacity: 1;
      }
    }
  }

  .footer-bottom {
    // border-top: 1px solid #2f3945;
    width: 100%;
    display: flex;
    align-items: center;
    color: white;

    .footer-bottom-left {
      opacity: 0.7;
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .footer-bottom-right {
      width: 50%;
      padding: 15px;
      padding-right: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a,
      img {
        margin-right: 15px;
        opacity: 0.7;

        &:last-child {
          margin-right: 0;
        }
      }

      img:hover,
      h1:hover {
        opacity: 1;
        cursor: pointer;
      }

      img {
        height: 20px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .footer-top {
      flex-direction: column;
      align-items: center;

      .footer-top-left,
      .footer-top-right {
        width: 100%;
        align-items: center;
        text-align: center;
        margin-bottom: 25px;
      }
    }

    .footer-bottom {
      flex-direction: column;

      .footer-bottom-left {
        width: 100%;
        justify-content: center;
      }

      .footer-bottom-right {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
</style>
