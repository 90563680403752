<template>
<div class="header pb-[75px] md:pb-[200px]">
  <div class="relative z-20 w-full pt-8 md:pt-12 justify-center flex flex-col items-center">
    <div class="w-11/12 text-center text-white mt-10">
      <h1 class="font-ammocorps text-3xl md:text-6xl lg:text-[5rem] leading-10 md:leading-none text-center">
        <span class="inline md:block">Introducing The</span> Stock Market for Ammo
      </h1>
      <h2 class="text-4xl md:text-4xl mt-4 md:mt-4 font-extralight opacity-80 pb-8"><span class="block md:inline">Your Ultimate Hedge </span>Against Uncertainty</h2>
    </div>
    <div class="playbutton-wrapper flex flex-col items-center w-11/12 md:w-10/12 text-center text-white md:mt-2 lg:mt-4" @click="openHowItWorksVideo">
      <h1 class="text-sm md:text-lg opacity-40 mb-8">WATCH THE VIDEO</h1>
      <div class="w-full flex justify-center items-center">
        <div class="h-12 md:h-20 w-4/12 flex flex-col justify-between items-end">
          <span class="block h-px w-full bg-white playbutton-stripes-left" />
          <span class="block h-px w-9/12 bg-white mr-4 playbutton-stripes-left" />
          <span class="block h-px w-full bg-white playbutton-stripes-left" />
        </div>
        <div class="h-16 md:h-24 w-16 md:w-24 playbutton shadow-xl">
          <div class="h-full w-full playbutton-inner"></div>
        </div>
        <div class="h-12 md:h-20 w-4/12 flex flex-col justify-between">
          <span class="block h-px w-full bg-white playbutton-stripes-right" />
          <span class="block h-px w-9/12 bg-white ml-4 playbutton-stripes-right" />
          <span class="block h-px w-full bg-white playbutton-stripes-right" />
        </div>
      </div>
    </div>
    <div class="mt-16">
      <a @click="openRegister" :class="[isDarkTheme ? 'text-gray-400' : 'text-gray-200']" class="px-10 py-2 text-2xl font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded">
        <span class="">Join the Platform</span>
      </a>
    </div>
  </div>
  <div class="animated-bg">
    <img style="transform: translateY(150px); left: 10%; animation-delay: 0.5s;" class="animated-dollar" src="@/assets/images/dollar.png" />
    <img style="transform: translateY(90px); left: 30%; animation-delay: 3s;" class="animated-dollar" src="@/assets/images/dollar.png" />
    <img style="transform: translateY(20px); left: 70%; animation-delay: 1.2s;" class="animated-dollar" src="@/assets/images/dollar.png" />
    <img style="transform: translateY(190px); left: 90%; animation-delay: 2s;" class="animated-dollar" src="@/assets/images/dollar.png" />
  </div>
  <div class="bg-image absolute z-10 px-2">
    <img style="height: intrinsic" src="@/assets/images/logoquilt.svg" />
  </div>
</div>
</template>

<script lang="ts">
import * as Vue from 'vue'
import OverlayHowItWorksVideo from '@/components/OverlayHowItWorksVideo.vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";

export default Vue.defineComponent({
  components: {},
  setup() {
    const emitter = useEmitter();

    return {
      emitter,
    };
  },
  mounted() {

  },
  methods: {
    openHowItWorksVideo() {
      this.emitter.emit('openHowItWorksVideo');
    },
     openRegister(event: MouseEvent) {
      event.stopImmediatePropagation();
      this.emitter.emit('openRegister');
    },
  }
});
</script>

<style lang="scss" scoped>
.header-nav {
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}

.header {
  width: 100%;
  min-height: calc(100vh - 5rem);
  background-color: #1f2b3a;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  .bg-image {
    position: absolute;
    bottom: -80px;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 700px) {
  .header {

    // overflow-y: hidden;
    .bg-image {
      bottom: -10px;
    }
  }
}

.animated-bg {
  position: absolute;
  bottom: -30px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;

  .animated-dollar {
    height: 32px;
    position: absolute;
    bottom: -30px;

    animation: dollarup 3s infinite;
  }

  @keyframes dollarup {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      bottom: 600px;
    }
  }
}

.playbutton {
  border-radius: 100%;
  border: 1px solid #546475;
  background-color: #34455a;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

  .playbutton-inner {
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background-color: rgba(255, 255, 255, 0.7);
    margin-left: 10%;
    height: 50%;
    width: 30%;
  }
}

.playbutton-wrapper:hover {
  cursor: pointer;

  .playbutton {
    background-color: rgba(255, 255, 255, 0.8);
    border-color: black;

    &:hover .playbutton-inner {
      background-color: #1a2533;
    }

    .playbutton-inner {
      background-color: #34455a;
    }
  }
}

.playbutton-stripes-left {
  background: linear-gradient(to right, rgba(84, 100, 117, 0), rgba(84, 100, 117, 1));
}

.playbutton-stripes-right {
  background: linear-gradient(to left, rgba(84, 100, 117, 0), rgba(84, 100, 117, 1));
}

//VIDEO
.video-section {
  background-color: #454e57;

  .click-to-play {
    cursor: pointer;
  }

  h2 {
    color: white;
  }
}

.logo-grid {
  position: relative;
  padding-top: 10px;

  .logo-row {
    margin: 0 auto;
    height: 55px;

    &>div {
      margin: 5px 0;
      padding-top: 8px;

      &:last-child {
        border-right: none;
      }
    }

    img {
      display: inline-block;
      height: 25px;
      color: white;

      &.winchester {
        height: 22px;
      }

      &.magtech,
      &.federal {
        height: 16px;
      }
    }
  }

  .logo-row:first-child {
    margin: 0 10%;
  }
}

//#8198AF
//white

.play-button {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 9px solid white;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -60px;
  background: #454e57;

  &:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    border: 7px solid #454e57;
    border-radius: 50%;
  }

  &>div {
    width: 65px;
    height: 100%;
    overflow: hidden;
    position: relative;
    left: 45px;

    &:before {
      content: "";
      background: #ffffff;
      width: 66px;
      height: 66px;
      display: block;
      transform: rotate(45deg);
      top: 20px;
      position: relative;
      left: -40px;
    }
  }
}
</style>
