<template>
  <div id="BasicLayout">
    <NavForPublic :isDarkTheme="navIsDarkTheme" />
    <div style="min-height: calc(100vh - 220px)">
      <ContentWrapper class="mt-10" v-if="ContentWrapper">
        <slot />
      </ContentWrapper>
      <slot v-else />
    </div>
    <Footer></Footer>
  </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import NavForPublic from "./nav/NavForPublic.vue";
import ContentWrapper from "./ContentWrapper.vue";
import Footer from "./Footer.vue";

export default Vue.defineComponent({
  components: {
    ContentWrapper,
    Footer,
    NavForPublic
},
  props: {
    ContentWrapper: Boolean,
    navIsDarkTheme: Boolean,
  },
  setup() {
    return {}
  }
});
</script>

