<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeOverlay">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
            <div >
              <div v-if="invite" class="free-box-of-ammo p-4 pl-5">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <GiftIcon class="h-8 w-8 mr-4 relative top-1" aria-hidden="true" />
                  </div>
                  <p class="text-sm font-medium text-gray-800">
                  <div>Your founding trader invite expires in</div>
                    <span class="countdown font-ammocorps text-xl">{{inviteCountdown.hours}} hours, {{inviteCountdown.minutes}} minutes, {{inviteCountdown.seconds}} seconds</span>
                  </p>
                </div>
              </div>
              
              <div class="p-4">
                <h1 class="font-ammocorps text-center pt-3 text-4xl">{{invite ? 'ACTIVATE' : 'CREATE'}} YOUR TRADING ACCOUNT</h1>

                <p  class="font-light">
                  Fill out the form below to create your account, or <a class="cursor-pointer text-indigo-600" @click="openLogin">click to login</a> if you're already a member.
                </p>

                <div v-if="formError" class="form-error rounded-md bg-red-50 p-4 mb-3 mt-5">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <ExclamationTriangleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
                    </div>
                    <div class="ml-3">
                      <h3 class="text-sm font-medium text-red-800">
                        {{formError}}
                      </h3>
                    </div>
                  </div>
                </div>

                <form class="space-y-6 w-5/5 mx-auto" @submit.native.prevent="createAccount">
                  <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div class="sm:col-span-3">
                      <label for="firstName" class="block text-sm font-medium text-gray-400">
                        First Name
                      </label>
                      <div class="mt-1">
                        <input v-model="model.firstName" id="firstName" name="firstName" type="text" autocomplete="given-name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </div>
                    </div>
                    <div class="sm:col-span-3">
                      <label for="lastName" class="block text-sm font-medium text-gray-400">
                        Last Name
                      </label>
                      <div class="mt-1">
                        <input v-model="model.lastName" id="lastName" name="lastName" type="text" autocomplete="family-name" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label for="email" class="block text-sm font-medium text-gray-400">
                      Email address
                    </label>
                    <div class="mt-1">
                      <input v-model="model.email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <div>
                    <label for="password" class="block text-sm font-medium text-gray-400">
                      Password
                    </label>
                    <div class="mt-1">
                      <input v-model="model.password" id="password" name="password" type="password" autocomplete="current-password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <p class="font-light text-sm pt-3">
                    By clicking "Create Account", you confirm that you are at least 18 years of age and have read the <router-link @click="closeOverlay" class="text-indigo-600" to="/legal">Terms and Conditions</router-link>.
                  </p>

                  <div class="">
                    <button type="submit" class="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-400 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      {{isSubmitting ? 'Creating Account...' : 'Create Account'}}
                    </button>
                  </div>
                </form>
                <!-- <div class="footer mt-3 relative -bottom-6 text-center border-t pt-3 text-indigo-600">
                  <router-link to="/pricing">Explore our Pricing Model</router-link>
                </div> -->
              </div>
            </div>
           
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import * as Vue from 'vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/outline'
import { ExclamationTriangleIcon, GiftIcon, PlayIcon } from '@heroicons/vue/20/solid'
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import inviteModule from "../modules/inviteModule";
import { useStream } from '../lib/Stream'

const platformDomain = process.env.VUE_APP_PLATFORM_DOMAIN || '';

export default Vue.defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    GiftIcon,
    PlayIcon,
    ExclamationTriangleIcon,
  },
  setup() {
    const store = useStore();
    const emitter = useEmitter();
    const router = useRouter();
    const stream = useStream();
    const isOpen = Vue.ref(false)
    const forceOpen = Vue.ref(false)
    const formError = Vue.ref('');

    const email = Vue.ref('')
    const firstName = Vue.ref('')
    const lastName = Vue.ref('')

    const { invite, inviteCountdown } = inviteModule(store);


    //check if we have any info on invite
    if (invite) {
      if(invite.email) email.value = invite.email;
      if(invite.firstName) firstName.value = invite.firstName;
      if(invite.lastName) lastName.value = invite.lastName
    }

    emitter.on('openRegister', (options = { forceOpen: false }) => {
      if (options.forceOpen) {
        forceOpen.value = true;
      }
      isOpen.value = true
    });

    emitter.on('closeRegister', () => {
      isOpen.value = false;
    });

    const model = {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: '',
    };

    return {
      emitter,
      stream,
      model,
      router,
      store,
      isOpen,
      forceOpen,
      invite,
      inviteCountdown,
      formError,
      isSubmitting: Vue.ref(false),
    }
  },
  methods: {
    closeOverlay() {
      if (this.forceOpen) return;
      this.isOpen = false;
    },
    async createAccount(evt: any) {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.formError = '';

        if (this.invite) {
                this.stream.createInviteEvent(this.invite.id, "Pressed Create Account - Waiting for response")
        }

      try {
        const data:any = {
          ...this.model,
        }
        if (this.invite) {
          data.inviteId = this.invite?.id
        }
        await this.store.dispatch('session/CreateAccount', data);
      } catch (error: any) {
        this.isSubmitting = false;
        this.formError = error.title;
        window.scrollTo(0, 0);
        if (this.invite) {
          this.stream.createInviteEvent(this.invite.id, "Create Account Failed - " + error.title)
        }
        return;
      }
      this.isSubmitting = false;
      this.isOpen = false;
      
      window.scrollTo(0, 0);
      setTimeout(() => {
        if(this.invite) {
          this.stream.createInviteEvent(this.invite.id, "Create Account Success - Sending To Platform")
        }
        window.location.href = platformDomain ? `//${platformDomain}/welcome` : '/welcome';
      }, 100);
    },
    openLogin() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openLogin'), 500);
    },
    openWaitlist() {
      this.isOpen = false;
      setTimeout(() => this.emitter.emit('openWaitlist'), 500);
    },
  }
});
</script>

<style lang="scss" scoped="">
  .free-box-of-ammo {
    background: #FFFAE1;
  }
</style>
