<template>
  <OverlayRegisterUSAE />
</template>

<script lang="ts">
import * as Vue from 'vue';
import OverlayRegisterUSAE from './OverlayRegisterUSAE.vue';

export default Vue.defineComponent({
  components: {
    OverlayRegisterUSAE,
  },
});
</script>
