<template>
  <BasicLayout :navIsDarkTheme="true">
    <Header />
    <div>
      <div class="relative pt-12 pb-14 w-5/5 mx-auto">
        <h1 class="font-ammocorps text-center text-xl md:text-3xl">How Investing In Ammo Works</h1>
        <ul
          class="grid grid-flow-row md:grid-flow-col divide-x text-center md:divide-x divide-gray-200 md:mt-16"
        >
          <li class="md:-mt-10 pt-5 pb-5">
            <div class="font-ammocorps text-4xl opacity-70">1</div>
            <div class="font-bold pb-2 md:text-xl">Create & Fund<br />Your Account</div>
            <p
              class="px-5 md:px-10 font-extralight text-tiny"
            >It's as simple as E*Trade or Robinhood. Use your credit card or a bank transfer.</p>
          </li>

          <li class="md:-mt-10 pt-5 pb-5">
            <div class="font-ammocorps text-4xl opacity-70">2</div>
            <div class="font-bold pb-2 md:text-xl">Buy At Real<br />Market Prices</div>
            <p
              class="px-5 md:px-10 font-extralight text-tiny"
            >We charge zero buying commissions. The prices you see are raw market prices from sellers.</p>
          </li>
          <li class="md:-mt-10 pt-5 pb-5">
            <div class="font-ammocorps text-4xl opacity-70">3</div>
            <div class="font-bold pb-2 md:text-xl">Store Free<br />In Our Armory</div>
            <p
              class="px-5 md:px-10 font-extralight text-tiny"
            >Every trade gives you free storage. Keep it and trade later or ship it to yourself whenever.</p>
          </li>
          <li class="md:-mt-10 pt-5 pb-5">
            <div class="font-ammocorps text-4xl opacity-70">4</div>
            <div class="font-bold pb-2 md:text-xl">Sell When the<br />Price Is Right</div>
            <p
              class="px-5 md:px-10 font-extralight text-tiny"
            >Buy low, sell high. Ammo held in our armories can be resold instantly to other buyers.</p>
          </li>
        </ul>
        <div class="border-bottom-inner absolute bottom-0 left-0 w-full bg-white"></div>
      </div>
      <div class="page-section relative overflow-hidden bg-white"> 
        <div class="section-top p-8 md:p-24 pb-0">
          <div class="w-12/12 lg:w-8/12">
            <h1
              class="font-ammocorps text-4xl mt-16 md:mt-0 mb-6"
            ><span class="md:block">YOU'LL ALWAYS FIND AMMO</span> IN-STOCK AND AT GREAT PRICES</h1>
            <p
              class="text-lg font-light"
            >USAE connects the world's largest suppliers directly to consumers. There are no middlemen, no empty shelves, and no pre-set prices. Our real-time trading engine gives everyone equal opportunity to buy and sell as free-market prices fluctuate to balance supply and demand.</p>
          </div>
        </div>
        <div class="section-bottom flex flex-row mt-5">
          <div
            class="flex-1 flex items-end invisible md:visible relative"
          >
            <div class="callout-text absolute top-[5%] left-8 md:left-24 w-10/12 bg-white flex flex-row">
              <div class="relative -top-3 font-light pr-2 text-[#214489]">Prices move in real time<br />as trades are executed</div>
              <div class="line flex-1 relative" style="top: 2px; height: 2px; background: #9EB5E2;"></div>
            </div>

            <div class="flex-1 w-full relative h-[60%]">
              <div class="absolute h-full w-[110%] -left-[10%] drop-shadow-lg flex flex-row">
                <div class="pallet-overflow flex-1 relative h-full">
                  <img class="absolute bottom-[105%] left-14 lg:left-20 opacity-60" src="@/assets/images/dog.svg" />
                  <div class="absolute bottom-[5%] right-0 h-full w-full">
                    <div class="flex-1 relative" style="background: url(/images/boxes-max.svg) repeat-x top right; background-size: auto 100%; right: -1.5px; height: calc(100% + 0.3px)"></div>
                  </div>
                  <div class="pallet w-full h-[7%] absolute -bottom-[1%]" style="background: url(/images/pallet-segment.svg) repeat-x top right; background-size: auto 100%;"></div>
                </div>

                <div class="pallet-partial relative h-full text-right w-[58%]">
                  <div class="absolute bottom-[5%] right-0 h-full w-full">
                    <img class="inline align-top max-h-full border-l border-gray-400" src="@/assets/images/boxes-max.svg" />
                    <img class="inline align-top max-h-full" src="@/assets/images/boxes-max.svg" />
                    <img class="inline align-top max-h-full" src="@/assets/images/boxes-max.svg" />
                    <img class="inline align-top max-h-full" src="@/assets/images/boxes-max.svg" />
                    <img class="inline align-top max-h-full -ml-[1px]" src="@/assets/images/boxes-end.svg" />
                  </div>
                  <div class="pallet w-full h-[7%] absolute -bottom-[1%] left-[7%]" style="background: url(/images/pallet-segment.svg) repeat-x top right; background-size: auto 100%;"></div>
                </div>
              </div>
            </div>

            <div class="w-5/12 relative -right-12 drop-shadow-lg">
              <img class src="@/assets/images/forklift.svg" />
            </div>
          </div>

          <div class="relative w-10/12 md:w-5/12 text-right">
            <div class="callout-lines hidden md:block absolute z-10 top-[5%] left-0 w-full h-full">
              <div class="line-vertical absolute top-0 left-[33%]" style="width: 6px; height: 27%; background: #9EB5E2; border: 2px solid white; border-bottom: none;"></div>
              <div class="line-horizontal absolute top-0 -left-[33%] z-[2]" style="width: calc(66% + 2px); height: 6px; background: #9EB5E2; border: 2px solid white; border-right: none; border-left: none;"></div>
            </div>
            <img
              class="shadow-xl relative top-10 inline-block border border-[#AFB3B4] rounded"
              style="transform: rotate(-5deg); box-shadow: 0 0 10px rgba(0,0,0,.2)"
              src="@/assets/images/platform.png"
            />
          </div>
        </div>
      </div>
      
      <div class="page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center">
        <div class="border-top-inner absolute top-0 left-0 w-full bg-white"></div>
        <a @click="openRegister" class="px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded">
          <span>Join the Platform</span>
        </a>
      </div>

      <div class="page-section relative overflow-hidden">
        <div class="border-top-inner absolute top-0 left-0 w-full bg-white"></div>
        <div class="section-top p-8 md:p-24 md:pb-0 mb-0 md:mb-24">
          <div class="w-12/12 flex flex-col items-end">
            <h1
              class="font-ammocorps text-4xl text-right mt-16 md:mt-0 mb-6 lg:w-8/12"
            ><span class="md:block">EVERY TRANSACTION IS INSPECTED,</span> CERTIFIED, AND INSURED</h1>
            <p
              class="text-lg font-light text-right lg:w-8/12"
            >All trades on the USAE are backed by real ammo. No exceptions. Sellers are required to ship their ammo to our secured armories where experts inspect and certify a broad range of attributes including brand, features, and quality. Only then does trading begin.</p>
          </div>
        </div>

        <div class="section-bottom flex flex-row mt-5 relative">
          <div class="callout-text absolute -top-[1%] right-8 md:right-24 w-6/12 bg-white flex flex-row invisible md:visible">
            <div class="line flex-1 relative" style="top: 2px; height: 2px; background: #9EB5E2;"></div>
            <div class="relative -top-3 font-light pl-2 text-[#214489] text-right">We guarantee every <br />round in your portfolio</div>
          </div>
          
          <div class="relative w-10/12 md:w-5/12 text-left">
            <div class="callout-lines hidden md:block absolute z-20 -top-[1%] left-0 w-full h-full">
              <div class="line-vertical absolute top-0 right-[14%]" style="width: 6px; height: 13%; background: #9EB5E2; border: 2px solid white; border-bottom: none;"></div>
              <div class="line-horizontal absolute top-0 -right-[14%] z-[2]" style="width: calc(28% + 2px); height: 6px; background: #9EB5E2; border: 2px solid white; border-right: none; border-left: none;"></div>
            </div>
            <img
              class="shadow-xl relative top-10 inline-block border border-[#AFB3B4] rounded z-10"
              style="transform: rotate(5deg); box-shadow: 0 0 10px rgba(0,0,0,.2)"
              src="@/assets/images/inspect.png"
            />
          </div>

          <div
            class="flex-1 invisible md:visible relative"
          >
            <div class="absolute w-[120%] right-[5%] bottom-0">
              <img class="drop-shadow-lg" src="@/assets/images/inspect-belt.svg" />
            </div>
          </div>
        </div>
        <div class="border-bottom-inner absolute bottom-0 left-0 w-full bg-white"></div>
      </div>

      <div class="page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center">
        <div class="border-top-inner absolute top-0 left-0 w-full bg-white"></div>
        <a @click="openRegister" class="px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded">
          <span>Join the Platform</span>
        </a>
      </div>

      <div class="page-section relative overflow-hidden bg-white">
        <div class="section-top p-8 md:p-24 md:pb-0 mb-10 md:mb-16">
          <div class="w-12/12 lg:w-8/12">
            <h1
              class="font-ammocorps text-4xl mt-16 md:mt-0 mb-6"
            >
              <span class="md:block">OUR REAL-TIME STORAGE PLATFORM </span>
              <span class="md:block">LETS YOU TAKE INSTANT ADVANTAGE </span>OF PRICE SWINGS</h1>
            <p
              class="text-lg font-light"
            >Inventory on the USAE is held in advanced high-tech armories, which are digitally connected into our trading platforms. This allows you to buy and sell at the click of a button — no packing, shipping, or handling of pallets. Legal ownership of your ammo is transfered instantly.</p>
          </div>
        </div>
        <div class="relative section-bottom h-36 sm:h-64 lg:h-[25rem] ml-8 md:ml-24 border-l border-gray-400">
          <div class="y-axis absolute -left-[14px] h-full">
            <div class="x-item top-[0%]">$5.00</div>
            <div class="x-item top-[19%]">$4.00</div>
            <div class="x-item top-[38%]">$3.00</div>
            <div class="x-item top-[57%]">$2.00</div>
            <div class="x-item top-[76%]">$1.00</div>
            <div class="x-item top-[95%]">$0.00</div>
          </div>
          <div class="returns left-[36%] top-[23%] text-red-500">-<span>9</span>%</div>
          <div class="returns left-[51%] top-[8%] text-green-500">+<span>42</span>%</div>
          <div class="returns left-[83%] top-[13%] text-red-500">-<span>13</span>%</div>
          <div class="returns left-[96.5%] -top-[7%] text-green-500">+<span>68</span>%</div>

          <div class="absolute left-0 w-full h-full z-50">
            <img
              class="w-full h-full z-40"
              style="transform: rotate(0deg)"
              src="@/assets/images/armory-lines.svg"
            />
          </div>
          <div class="absolute right-0 w-10/12 md:w-7/12 lg:w-6/12 z-50" style="bottom: -5px">
            <img
              class="absolute w-[104%] -left-[2%] bottom-0 z-20 max-w-none"
              style="transform: rotate(0deg)"
              src="@/assets/images/armory-bg.svg"
            />
            <img
              class="relative w-12/12 z-40"
              style="transform: rotate(0deg)"
              src="@/assets/images/armory.svg"
            />
          </div>
        </div>
      </div>

      <div class="page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center">
        <div class="border-top-inner absolute top-0 left-0 w-full bg-white"></div>
        <a @click="openRegister" class="px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded">
          <span>Join the Platform</span>
        </a>
      </div>

      <div class="page-section relative overflow-hidden">
        <div class="border-top-inner absolute top-0 left-0 w-full bg-white"></div>
        <div class="section-top p-8 md:p-24 md:pb-0 mb-0 md:mb-20">
          <div class="w-12/12 flex flex-col items-end">
            <h1
              class="font-ammocorps text-4xl text-right mt-16 md:mt-0 mb-6 lg:w-8/12"
            ><span class="md:block">THE ONLY INVESTMENT PORTFOLIO </span>YOU CAN SAFELY SHOOT</h1>
            <p
              class="text-lg font-light text-right lg:w-8/12"
            >It’s your ammo. Whether you need boxes for a hunting trip or just want to spend an hour at the shooting range, your ammo is always ready. Open your portfolio and click “Ship to Me”. Our team will ship your boxes within 48 hours. It’s like having your own personal armory; actually, that’s exactly what it is.</p>
          </div>
        </div>

        <div class="section-bottom flex flex-row mt-0 relative">
          <div class="callout-text absolute top-0 right-8 md:right-[25%] w-4/12 flex flex-row invisible md:visible">
            <div class="line flex-1 relative" style="top: 2px; height: 2px; background: #9EB5E2;"></div>
            <div class="relative -top-3 font-light pl-2 text-[#214489] text-right">Ship it on demand<br /> whenever you want</div>
          </div>

          <div class="relative w-10/12 md:w-5/12 text-left">
            <div class="callout-lines hidden md:block absolute z-20 -top-0 left-0 w-full h-full">
              <div class="line-vertical absolute top-0 right-[17%]" style="width: 6px; height: 60%; background: #9EB5E2; border: 2px solid white; border-bottom: none;"></div>
              <div class="line-horizontal absolute top-0 -right-[17%] z-[2]" style="width: calc(34% + 2px); height: 6px; background: #9EB5E2; border: 2px solid white; border-right: none; border-left: none;"></div>
            </div>
            <img
              class="shadow-xl relative -bottom-36 -mt-24 inline-block border border-[#AFB3B4] rounded z-10"
              style="transform: rotate(5deg); box-shadow: 0 0 10px rgba(0,0,0,.2)"
              src="@/assets/images/shipping.png"
            />
          </div>

          <div
            class="flex-1 invisible md:visible relative"
          >
            <div class="absolute top-0 right-[16%]">
              <img class="drop-shadow-lg h-24" src="@/assets/images/sun.svg" />  
            </div>
            <div class="absolute w-[105%] right-0 bottom-0">
              <img class="drop-shadow-lg" src="@/assets/images/hunting-cabin.svg" />
            </div>
          </div>
        </div>
        <div class="border-bottom-inner absolute bottom-0 left-0 w-full bg-white"></div>
      </div>
      
      <div class="page-section relative overflow-hidden py-10 bg-[#F3F6F9] text-center">
        <div class="border-top-inner absolute top-0 left-0 w-full bg-white"></div>
        <a @click="openRegister" class="px-10 py-2 text-2xl text-white font-light cursor-pointer border border-black bg-indigo-500 hover:bg-indigo-400 rounded">
          <span>Join the Platform</span>
        </a>
      </div>
      
      <div class="bg-white w-full flex flex-col items-center pt-12 md:pt-24 pb-24">
        <h1
          class="pt-4 mb-12 text-4xl font-ammocorps text-center w-10/12 md:w-6/12"
        ><span class="inline md:block">POWERFUL TRADING TOOLS </span>DELIVER SIMPLICITY AND SPEED</h1>
        <div class="flex flex-col md:flex-row w-full lg:w-9/12">
          <div class="w-full md:w-6/12 py-8 px-12 text-center md:text-left">
            <h1 class="text-xl font-ammocorps">transparent pricing</h1>
            <p>The USAE shows no favoritism among its traders. Everyone sees the same price at the same time for every trade.</p>
            <img src="@/assets/images/transpricing.png" class="inline h-40 mt-6" />
          </div>
          <div class="w-full md:w-6/12 py-8 px-12 text-center md:text-left">
            <h1 class="pt-4 text-xl font-ammocorps">buy/sell order books</h1>
            <p>Real-time order books give you full transparency into where other traders are setting buy and sell prices.</p>
            <img src="@/assets/images/buy:sell.png" class="inline h-40 mt-6" />
          </div>
        </div>
        <div class="flex flex-col md:flex-row w-full lg:w-9/12">
          <div
            class="w-full md:w-6/12 py-8 px-12 text-center md:text-left"
          >
            <h1 class="text-xl font-ammocorps">historical insights</h1>
            <p>Historical price trends can indicate the future direction of a market. The USAE provides up to 90 days of free pricing data for every product.</p>
            <img src="@/assets/images/FreeLimit.png" class="inline h-40 mt-6" />
          </div>
          <div
            class="w-full md:w-6/12 py-8 px-12 text-center md:text-left"
          >
            <h1 class="text-xl font-ammocorps">free limit orders</h1>
            <p>Whether you’re buying or selling, USAE’s limit orders put you in the driver’s seat. Set your perferred price and your trade executes only when the price matches.</p>
            <img src="@/assets/images/histinsights.png" class="inline h-40 mt-6" />
          </div>
        </div>
      </div>
    </div>
  </BasicLayout>
  <OverlayHowItWorksVideo />
</template>

<script lang="ts">
import * as Vue from 'vue';
import ContentWrapper from '@/layouts/ContentWrapper.vue';
import useEmitter from "@contrarian/ui-shared/composables/useEmitter";
import { useStream } from "@contrarian/ui-shared/lib/Stream"
import { useStore } from "vuex";
import OverlayHowItWorksVideo from '@/components/OverlayHowItWorksVideo.vue';
import Header from '@/components/Header.vue';

export default Vue.defineComponent({
  components: {
    ContentWrapper,
    OverlayHowItWorksVideo,
    Header,
  },
  data() {
    return {
      launchDate: new Date('07/01/2022').getTime(),
      time: {
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0',
      },
      count: 0,
      test: ''
    }
  },
  watch: {
    'time.seconds': {
      handler(newValue, oldValue) {
        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var now = new Date().getTime();
        var distance = this.launchDate - now;
        var days = Math.floor(distance / _day);
        var hours = Math.floor((distance % _day) / _hour);
        var minutes = Math.floor((distance % _hour) / _minute);
        var seconds = Math.floor((distance % _minute) / _second);

        setTimeout(() => {
          this.time.days = days + ''
          this.time.hours = hours + ''
          this.time.minutes = minutes + ''
          this.time.seconds = seconds + ''
        }, 1000)
      },
      immediate: true,
      deep: true
    }
  },
  setup() {
    const emitter = useEmitter();
    const store = useStore();
    const stream = useStream();
    if(!store.state.session.siteVisitedWithCode && store.state.session.invite) {
        if (store.state.session.invite.isInPerson) {
          //If they have a isInPerson we know they are in-person, go straight to login
            stream.createInviteEvent(store.state.session.invite.id, "Opening Register - User Needs To Create Password")
            emitter.emit('openRegister');
        } else {
          //If they don't have a isInPerson we know they aren't in-person, show founding trader info
            stream.createInviteEvent(store.state.session.invite.id, "User is on Founding Trader Overlay becuase they are listed as not in-person")
            emitter.emit('openCardDetails');
          
        }
      
    }
    return {
      emitter,
    };
  },
  methods: {
  openRegister(event: MouseEvent) {
      event.stopImmediatePropagation();
      this.emitter.emit('openRegister');
    },
    openHowItWorksVideo() {
      this.emitter.emit('openHowItWorksVideo');
    },
    openFoundingTrader() {
      this.emitter.emit('openFoundingTrader');
    },
    goto(refName: any) {
      var element: any = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    }
  }
});
</script>

<style lang="scss" scoped>
.page-section {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.countdown-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #34455a;
  .countdown-count {
    display: flex;
    justify-content: center;
    .countdown-count-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @apply mr-4 lg:mr-8;
      .num {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: #34455a;
        @apply w-[30px] pt-[7px] text-4xl font-ammocorps;
        @apply md:w-[60px] md:pt-[15px] md:mb-[15px];
        @apply lg:w-[80px] lg:text-8xl;
        border-radius: 5px;
      }
    }
  }
}
.video-section {
  background-color: #454e57;
  .click-to-play {
    cursor: pointer;
  }
  h2 {
    color: white;
  }
}

.logo-grid {
  position: relative;
  padding-top: 10px;
  .logo-row {
    margin: 0 auto;
    height: 55px;
    & > div {
      margin: 5px 0;
      padding-top: 8px;
      &:last-child {
        border-right: none;
      }
    }
    img {
      display: inline-block;
      height: 25px;
      color: white;
      &.winchester {
        height: 22px;
      }
      &.magtech,
      &.federal {
        height: 16px;
      }
    }
  }
  .logo-row:first-child {
    margin: 0 10%;
  }
}

.returns {
  position: absolute;
  background: white;
  border: 1px solid #888e92;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
  @apply w-[30px] h-[30px] leading-[28px] text-[12px];
  @apply md:w-[60px] md:h-[60px] md:leading-[58px] md:text-[16px];
  box-shadow: 1px 1px 8px rgba(0,0,0,0.2);
  z-index: 60;
  span {
    @apply hidden md:inline;
  }
}

.x-item {
  position: absolute;
  right: 0;
  text-align: right;
  @apply text-slate-400 font-light;
}

.play-button {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 9px solid white;
  position: absolute;
  left: 50%;
  top: 0;
  margin-left: -60px;
  background: #454e57;
  &:before {
    content: "";
    position: absolute;
    top: -15px;
    left: -15px;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    border: 7px solid #454e57;
    border-radius: 50%;
  }
  & > div {
    width: 65px;
    height: 100%;
    overflow: hidden;
    position: relative;
    left: 45px;
    &:before {
      content: "";
      background: #ffffff;
      width: 66px;
      height: 66px;
      display: block;
      transform: rotate(45deg);
      top: 20px;
      position: relative;
      left: -40px;
    }
  }
}

.border-bottom-inner {
  box-shadow: 0 -1px 3px rgba(0,0,0,0.3);
  height: 1px;
  z-index: 100;
}

.border-top-inner {
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
  height: 1px;
  z-index: 100;
}

.account-welcome {
  @apply border border-gray-300 shadow border-t-0;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 0 0 4px 4px;
}

h2 {
  @apply text-2xl text-gray-500 font-thin pb-3;
}
ol.trending-list {
  @apply block bg-white rounded-lg shadow;
  padding: 1px 2px;
  li {
    @apply border-b;
    padding-top: 1px;
    &:first-child {
      a {
        border-radius: 5px 5px 0 0;
      }
    }
    &:last-child {
      border-bottom: none;
      a {
        border-radius: 0 0 5px 5px;
      }
    }
    a {
      @apply block py-3 px-4 hover:bg-blue-50;
      margin-bottom: 1px;
    }
    .icon {
      @apply w-6 h-6 mr-3 inline-block align-middle;
      img {
        @apply w-6;
      }
    }
    .label {
      @apply inline-block;
    }
    .rounds {
      @apply float-right;
    }
  }
}

.register-callout {
  position: relative;
  margin-top: -140px;
  button {
    z-index: 2;
    background-color: rgba(243, 246, 249, 1);
    &:hover {
      background-color: #f8f9fa;
      box-shadow: inset 1px 1px 0 white;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: -173px;
    left: 0;
    height: 175px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(243, 246, 249, 0) 0%,
      rgba(243, 246, 249, 1) 100%
    );
    z-index: 1;
    pointer-events: none;
  }
}

.prices-section {
  min-height: 800px;
  max-width: 1200px;
  width: 100%;
  position: relative;
}
.section-content {
  position: absolute;
  left: 8%;
  top: 8%;
  width: 80%;
  min-width: 300px;
  z-index: 100;
  padding-left: 16px;
  padding-right: 16px;

  h1,
  h2 {
    color: #1f2b3a;
  }
}
.section-overlay {
  position: absolute;
  z-index: 50;
  bottom: 0;
  right: 0;
  width: 600px;
  .section-overlay-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 35px;
    padding-bottom: 0;
    img {
      margin-left: 55px;
      transform: rotate(-3deg);
    }
  }
}

@media screen and (max-width: 800px) {
  .prices-section {
    min-height: 800px;
  }
  .section-overlay {
    position: absolute;
    z-index: 50;
    bottom: 0;
    right: 0;
    width: 400px;
    .section-overlay-inner {
      position: relative;
      overflow: hidden;
      height: 100%;
      padding: 35px;
      padding-bottom: 0;
      img {
        margin-left: 55px;
        transform: rotate(-3deg);
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .section-content {
    left: 0;
    right: 0 !important;
  }
}

.section-bottom-bg {
  position: absolute;
  z-index: 1;
  bottom: 0;
  img {
    width: 30%;
  }
  @media screen and (max-width: 800px) {
    img {
      width: 200%;
    }
  }
}

.inspection-section {
  min-height: 800px;
  max-width: 1200px;
  width: 100%;
  position: relative;
  .section-content {
    left: auto;
    right: 8%;
    top: 8%;
    text-align: right;
    width: 80%;
  }
  .section-bottom-bg {
    width: 100%;
    img {
      width: 100%;
      max-width: 1000px;
    }
  }
}
.armory-section {
  min-height: 800px;
  max-width: 1200px;
  width: 100%;
  position: relative;
  .section-overlay {
    width: 100%;
    max-width: 900px;
    img {
      margin: 0;
      transform: rotate(0);
    }
  }
}

.hunter-section {
  min-height: 800px;
  max-width: 1200px;
  width: 100%;
  position: relative;
  margin-bottom: 50px;
  .section-overlay {
    img {
      transform: rotate(0);
      @media screen and (max-width: 500px) {
        max-width: 250px;
        margin-left: 150px;
      }
    }
  }
  .section-bottom-bg {
    width: 70%;
    max-width: 400px;
    z-index: 99;
    img {
      width: 100%;
      max-width: 1000px;
    }
  }
}

.background-animation-container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.animated-cents {
  position: absolute;
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #454e57;
  border-radius: 100%;
  opacity: 0;
  animation-name: centsUp;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  h1 {
    color: #454e57;
  }
}

@keyframes centsUp {
  0% {
    opacity: 0;
    top: 90%;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    top: 30%;
  }
}
</style>
