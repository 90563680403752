import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Index from "../pages/Index.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: Index,
  }, {
    path: "/free-box-of-ammo",
    name: "FreeBoxOfAmmo",
    component: () => import("../pages/FreeBoxOfAmmo.vue"),
  }, {
    path: "/pricing",
    name: "Pricing",
    component: () => import("../pages/Pricing.vue"),
  }, {
    path: "/legal",
    name: "Legal",
    component: () => import("../pages/Legal.vue"),
  }, {
    path: "/why-ammo-now",
    name: "WhyAmmoNow",
    component: () => import("../pages/WhyAmmoNow.vue"),
  }, {
    path: "/how-it-works",
    name: "HowItWorks",
    component: () => import("../pages/HowItWorks.vue"),
  }, {
    path: "/faqs",
    name: "FAQs",
    component: () => import("../pages/FAQs.vue"),
  }, {
    path: "/spots-open",
    name: "SpotsOpen",
    component: () => import("../pages/SpotsOpen.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../pages/AdminAuth.vue"),
  },
  {
    path: "/:shortCode([1-9A-NP-Z]{5})",
    component: () => import("../pages/FreeBoxOfAmmo.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
